/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Dirigos API
 * OpenAPI spec version: 1.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { OutboxDto, GetInboxParams, GetOutboxParams } from "./model";
import { http } from "../http";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const health = (
  client: string,
  application: string,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<void>(
    {
      url: `/api/v1/clients/${client}/${application}/health`,
      method: "get",
      signal,
    },
    options
  );
};

export const getHealthQueryKey = (client: string, application: string) => [
  `/api/v1/clients/${client}/${application}/health`,
];

export type HealthQueryResult = NonNullable<Awaited<ReturnType<typeof health>>>;
export type HealthQueryError = unknown;

export const useHealth = <
  TData = Awaited<ReturnType<typeof health>>,
  TError = unknown
>(
  client: string,
  application: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof health>>, TError, TData>;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getHealthQueryKey(client, application);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof health>>> = ({
    signal,
  }) => health(client, application, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof health>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!(client && application), ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getInbox = (
  client: string,
  application: string,
  params?: GetInboxParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<OutboxDto>(
    {
      url: `/api/v1/clients/${client}/${application}/inbox`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetInboxQueryKey = (
  client: string,
  application: string,
  params?: GetInboxParams
) => [
  `/api/v1/clients/${client}/${application}/inbox`,
  ...(params ? [params] : []),
];

export type GetInboxQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInbox>>
>;
export type GetInboxQueryError = unknown;

export const useGetInbox = <
  TData = Awaited<ReturnType<typeof getInbox>>,
  TError = unknown
>(
  client: string,
  application: string,
  params?: GetInboxParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInbox>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetInboxQueryKey(client, application, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInbox>>> = ({
    signal,
  }) => getInbox(client, application, params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getInbox>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!(client && application), ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getOutbox = (
  client: string,
  application: string,
  params?: GetOutboxParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<OutboxDto>(
    {
      url: `/api/v1/clients/${client}/${application}/outbox`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetOutboxQueryKey = (
  client: string,
  application: string,
  params?: GetOutboxParams
) => [
  `/api/v1/clients/${client}/${application}/outbox`,
  ...(params ? [params] : []),
];

export type GetOutboxQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOutbox>>
>;
export type GetOutboxQueryError = unknown;

export const useGetOutbox = <
  TData = Awaited<ReturnType<typeof getOutbox>>,
  TError = unknown
>(
  client: string,
  application: string,
  params?: GetOutboxParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOutbox>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetOutboxQueryKey(client, application, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOutbox>>> = ({
    signal,
  }) => getOutbox(client, application, params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOutbox>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!(client && application), ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
