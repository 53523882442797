import React from "react";
import {
  Box,
  List, ListItem,
  ListItemButton, ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { Outlet, useParams } from "react-router-dom";
import { NavLink } from "../utils/router/NavLink";
import { useGetClient } from "../api-client/clients";
import {ClientDto} from "../api-client/model";
import {ClientIcon} from "../ui/ClientIcon";

export const ClientContext = React.createContext<ClientDto | null>(null);

export function ClientManager() {
  const params = useParams();
  const clientId = params.clientId as string;
  const clientQuery = useGetClient(clientId);
  const client = clientQuery.data ?? null;

  return (
    <ClientContext.Provider value={client}>
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", backgroundColor: "#fff", flex: 1 }}>
          <List
            component="nav"
            sx={{ maxWidth: 220, width: "100%", pt: 0, boxShadow: 1 }}
          >
            <ListItem sx={{ pt: 2 }}>
              <ListItemIcon sx={{ minWidth: 50 }}>
                {client && <ClientIcon client={client} isActive />}
              </ListItemIcon>
              <ListItemText
                primary={client?.name}
                primaryTypographyProps={{ fontSize: 16, fontWeight: 'bold', lineHeight: 1.1 }}
              />
            </ListItem>
            {client?.services?.map(service => (
                <ListItemButton component={NavLink} to={service.name.toLowerCase()} key={service.name} end>
                    <ListItemText
                      primary={service.name}
                      secondary={service.application}
                      primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                      secondaryTypographyProps={{ fontSize: 12 }}
                    />
                </ListItemButton>
            ))}
          </List>

          <Box
            component="main"
            sx={{
              p: 4,
              backgroundColor: (theme) => theme.palette.grey[100],
              flexGrow: 1,
            }}
          >
            {client ? <Outlet /> : null}
          </Box>
        </Box>
      </Box>
    </ClientContext.Provider>
  );
}
