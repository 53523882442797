import {useGetInbox} from "../../../api-client/system";
import React, {useState} from "react";
import {Viewer, Differ, DiffResult} from 'json-diff-kit';
import 'json-diff-kit/dist/viewer.css';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {dateTimeFormat} from "../../../utils/date";
import {GetOutboxParams} from "../../../api-client/model";
import {useDebounce} from "use-debounce";

const differ = new Differ();

type Props = {
  clientId: string,
  application: string
};

export const Inbox: React.FC<Props> = ({ clientId, application }) => {
  const [diffOpen, setDiffOpen] = useState<readonly [DiffResult[], DiffResult[]] | null>(null);
  const [filter, setFilter] = useState<GetOutboxParams>({
    status: "all",
    page: 0,
    size: 10,
  });
  const [debouncedType] = useDebounce(filter.type, 350);

  const inboxQuery = useGetInbox(clientId, application, { ...filter, type: debouncedType });
  const data = inboxQuery.data;

  return (
      <>
          <Typography variant="h6" gutterBottom>
            Inbox
          </Typography>

          <Toolbar sx={{ mb: 1 }} disableGutters>
              <FormControl>
                  <TextField
                      label="Type"
                      variant="standard"
                      value={filter.type ?? ""}
                      onChange={(e) => setFilter({ ...filter, type: e.target.value })}
                  />
              </FormControl>
          </Toolbar>

          <Paper>
              <TableContainer>
                  <Table size="small">
                      <TableHead>
                          <TableRow>
                              <TableCell>ID</TableCell>
                              <TableCell>Type</TableCell>
                              <TableCell>Vytvořeno</TableCell>
                              <TableCell>Zpracováno</TableCell>
                              <TableCell />
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {inboxQuery.isLoading &&
                              new Array(filter.size ?? 0).fill(0).map((_, i) => (
                                  <TableRow key={i} sx={{ height: 34.3 }}>
                                      <TableCell>
                                          <Skeleton variant="text" />
                                      </TableCell>
                                      <TableCell>
                                          <Skeleton variant="text" />
                                      </TableCell>
                                      <TableCell>
                                          <Skeleton variant="text" />
                                      </TableCell>
                                      <TableCell>
                                          <Skeleton variant="text" />
                                      </TableCell>
                                      <TableCell>
                                          <Skeleton variant="text" />
                                      </TableCell>
                                  </TableRow>
                              ))}
                          {data?.items.map((message) => {
                              const isUpdate = (message.data as any)?.event?.after;

                              return (
                                  <TableRow key={message.id}>
                                      <TableCell>{message.id}</TableCell>
                                      <TableCell>{message.type}</TableCell>
                                      <TableCell>{dateTimeFormat(message.occurredOn)}</TableCell>
                                      <TableCell>
                                          {message.processedDate
                                              ? dateTimeFormat(message.processedDate)
                                              : "-"}
                                      </TableCell>
                                      <TableCell>
                                          <Button size="small" onClick={() => setDiffOpen(differ.diff((message.data as any)?.event.before, (message.data as any)?.event.after))}>{isUpdate ? 'Diff' : null}</Button>
                                      </TableCell>
                                  </TableRow>
                              );
                          })}
                      </TableBody>
                  </Table>
              </TableContainer>

              <TablePagination
                  component="div"
                  count={data?.total ?? -1}
                  page={filter.page ?? 0}
                  onPageChange={(e, page) => setFilter({ ...filter, page })}
                  rowsPerPage={filter.size ?? 10}
                  onRowsPerPageChange={(e) =>
                      setFilter({
                          ...filter,
                          size: parseInt(e.target.value),
                          page: 0,
                      })
                  }
              />
          </Paper>

          <Dialog open={diffOpen != null} maxWidth="lg" onClose={() => setDiffOpen(null)} scroll="paper">
              <Box>
                  <DialogTitle>Diff</DialogTitle>
                  <DialogContent dividers>
                      {diffOpen && <Viewer diff={diffOpen} />}
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={() => setDiffOpen(null)}>Zrušit</Button>
                  </DialogActions>
              </Box>
          </Dialog>
      </>
  )
};
