import {
  Box,
  CircularProgress,
  Container, debounce,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography
} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {useGetLogs} from "../api-client/logs";
import {dateTimeFormat} from "../utils/date";
import {GetLogsParams} from "../api-client/model";
import {useGetClients} from "../api-client/clients";
import {useNavigate, useSearchParams} from "react-router-dom";
import {LogLevelColor} from "./LogLevel";
import {Clear, Search} from "@mui/icons-material";
import {useDebounce} from "use-debounce";

const LEVELS = [
  "INFO",
  "WARNING",
  "ERROR",
  "CRITICAL",
  "ALERT",
];

export const Logs = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const params = searchParams.entries();
  const navigate = useNavigate();
  const [filter, setFilter] = useState<GetLogsParams>({ ...Object.fromEntries(params), page: 0, size: 100 });
  const [debouncedQuery] = useDebounce(filter.query, 350, {});
  const logsQuery = useGetLogs({ ...filter, query: debouncedQuery });
  const logs = logsQuery.data || [];
  const clientsQuery = useGetClients();
  const clients = clientsQuery.data || [];


  useEffect(() => {
    setSearchParams(Object.fromEntries(Object.entries(filter).filter(([_, v]) => v != null)) as Record<string, string>, {
      replace: true
    });
  }, [filter, setSearchParams]);

  const filteredClient = useMemo(() => filter.client ? clients.find(x => x.code === filter.client) : undefined, [filter, clients]);

  return (
    <Container sx={{ p: 4 }} maxWidth={false}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h4">Logs</Typography>
      </Box>

      <Paper>
        <Toolbar sx={{ py: 3 }}>
          <FormControl size="small" fullWidth sx={{ width: 200, mr: 3 }}>
            <InputLabel>Client</InputLabel>
            <Select
              label="Client"
              value={filter.client}
              onChange={e => setFilter(x => ({ ...x, client: e.target.value, context: undefined }))}
            >
              <MenuItem>-</MenuItem>
              {clients?.map(client => (
                <MenuItem key={client.id} value={client.code}>{client.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl size="small" sx={{ width: 200, mr: 3 }} placeholder="Context">
            <InputLabel id="logs-select-ctx">Context</InputLabel>
            <Select
              label="Context"
              disabled={!filter.client}
              value={filter.context}
              onChange={e => setFilter(x => ({ ...x, context: e.target.value }))}
            >
              <MenuItem>-</MenuItem>
              {filteredClient?.services?.map(service => (
                <MenuItem key={service.logCode} value={service.logCode}>{service.logCode}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <RadioGroup
              row
              name="level"
              value={filter.level || 'all'}
              onChange={(e, level) => {
                setFilter(x => ({
                  ...x,
                  level: level === 'all' ? undefined : level,
                  page: 0,
                }));
              }}
            >
              <FormControlLabel value="all" control={<Radio />} label="All" />
              {LEVELS.map(level => (
                <FormControlLabel
                  key={level}
                  value={level}
                  control={<Radio size="small" />}
                  label={level}
                />
              ))}
            </RadioGroup>
          </FormControl>

          <FormControl sx={{ flex: 1 }}>
            <TextField
              variant="outlined"
              size="small"
              value={filter.query ?? ""}
              InputProps={{
                startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
                endAdornment: filter.query ? (
                  <IconButton size="small" onClick={() => setFilter({ ...filter, query: '' })}>
                    <Clear />
                  </IconButton>
                ) : null
              }}
              onChange={(e) => setFilter({ ...filter, query: e.target.value })}
            />
          </FormControl>
        </Toolbar>

        {logsQuery.isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Client</TableCell>
                  <TableCell>Context</TableCell>
                  <TableCell>Level</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Logged at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs?.map(entry => (
                  <TableRow key={entry.id} hover onClick={() => {
                    navigate(`/logs/${entry.messageId || entry.id}`);
                  }} sx={{ cursor: 'pointer' }}>
                    <TableCell>{entry.id}</TableCell>
                    <TableCell>{entry.client}</TableCell>
                    <TableCell>{entry.context}</TableCell>
                    <TableCell><Typography fontSize="small" color={LogLevelColor[entry.level]}>{entry.level}</Typography></TableCell>
                    <TableCell>{entry.message?.substring(0, 120)}</TableCell>
                    <TableCell>{dateTimeFormat(entry.createdAt)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <TablePagination
              component="div"
              count={-1}
              page={filter.page ?? 0}
              onPageChange={(e, page) => setFilter(x => ({ ...x, page }))}
              rowsPerPage={filter.size ?? 100}
              onRowsPerPageChange={(e) =>
                setFilter(x => ({
                  ...x,
                  size: parseInt(e.target.value),
                  page: 0,
                }))
              }
            />
          </>
        )}
      </Paper>
    </Container>
  );
}
